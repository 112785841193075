import Onboard from "bnc-onboard";
import Web3 from "web3";

let _web3;
export let getWeb3 = () => {
    if (_web3) {
        return Promise.resolve(_web3);
    }
    return onboard
        .walletSelect()
        .then((success) => {
            if (success) {
                return onboard.walletCheck();
            } else {
                throw "User rejected";
            }
        })
        .then((success) => {
            if (success) {
                return _web3;
            }
        });
};

const onboard = Onboard({
    dappId: "37182c69-7db8-4a1d-b64f-c95e4ef5b5f1",
    networkId: parseInt(process.env.VUE_APP_NETWORK_ID || 3),
    subscriptions: {
        wallet: (wallet) => {
            _web3 = new Web3(wallet.provider);
        },
    },
    darkMode: true,
    walletSelect: {
        wallets: [
            { walletName: "coinbase", preferred: true },
            { walletName: "metamask", preferred: true },
            { walletName: "torus", preferred: true },
            { walletName: "authereum", preferred: true },
            { walletName: "status" },
        ],
    },
    walletCheck: [{ checkName: "derivationPath" }, { checkName: "accounts" }, { checkName: "connect" }, { checkName: "network" }],
});
